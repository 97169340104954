.not-found {
    min-height: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-found-redirect {
    font-size: 22px;
    cursor: pointer;
    font-weight: 700;
    border-bottom: 1px solid black;
    margin-top: 30px;
}
