footer {
    background: rgb(40, 41, 40);
    padding: 54px 0px 40px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 36px;
}

.footer-block {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
}

.footer-logo {
    max-width: 305px;
    width: calc(100% / 3);
}

.footer-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    font-size: 27px;
}

.footer-title-text {
    color: white;
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 15px;
}

.footer-title-decoration-line {
    background-color: rgb(175, 169, 143);
    min-height: 2px;
    min-width: 89px;
}

.footer-title-decoration-line-lg {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 89px;
    margin-bottom: 4px;
}

.footer-title-decoration-line-md {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 47px;
}

.footer-description {
    color: rgb(183, 176, 149);
    font-size: 20px;
}

.footer-copyright {
    display: flex;
    justify-content: center;
}

.footer-line {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.footer-location-icon,
.footer-phone-icon {
    background-color: white;
    border-radius: 50%;
    padding: 3px;
    width: 32px;
    margin-right: 12px;
}

.footer-facebook-icon,
.footer-instagram-icon {
    background-color: rgb(166, 160, 135);
    border-radius: 50%;
    padding: 3px;
    width: 32px;
    margin: 5px;
}

.footer-social-media {
    display: flex;
    justify-content: center;
}

@media all and (max-width: 850px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }
    .footer-block {
        width: calc(100% - 50px);
        margin-left: 0px;
        margin-top: 40px;
        text-align: center;
    }
}
@media all and (max-width: 550px) {
    .footer-logo {
        max-width: 100%;
        width: calc(100% / 2);
    }
}
