.about-restaurant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0px;
}

.about-restaurant-description {
    max-width: 630px;
    color: rgb(136, 132, 115);
    margin-bottom: 50px;
}

.about-restaurant-description p {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.6px;
}
.about-restaurant-title {
    color: rgb(183, 176, 149);
    font-size: 40px;
    font-family: 'Cormorant Infant', sans-serif;
    margin-bottom: 15px;
}

.about-restaurant-title-decoration-line-lg {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 89px;
    margin-bottom: 4px;
}
.about-restaurant-title-decoration-line-md {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 47px;
    margin-bottom: 35px;
}

.about-restaurant-reserve {
    color: rgb(52, 51, 50);
    border-bottom: 1px solid rgb(52, 51, 50);
    padding: 9px 0px;
    font-weight: 700;
    font-size: 23px;
}

.about-restaurant-image {
    max-width: 346px;
    width: 50%;
    margin-right: 50px;
    border-radius: 10px;
}

@media all and (max-width: 1300px) {
    .about-restaurant {
        align-items: flex-start;
        padding: 100px 0px;
    }
}
@media all and (max-width: 1100px) {
    .about-restaurant {
        align-items: flex-start;
        padding: 50px 0px;
    }
}

@media all and (max-width: 900px) {
    .about-restaurant {
        padding: 40px 0px;
    }
    .about-restaurant-image {
        width: 40%;
    }
}

@media all and (max-width: 900px) {
    .about-restaurant-image {
        display: none;
    }
}
