@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Cormorant', sans-serif;
    font-style: normal;
    letter-spacing: 0.3px;
    line-height: normal;
    text-decoration: none;
}

.container {
    max-width: calc(100vw - 267px);
    margin: 0 auto;
}

@media all and (max-width: 1100px) {
    .container {
        max-width: calc(100vw - 100px);
    }
}

@media all and (max-width: 450px) {
    .container {
        max-width: calc(100vw - 50px);
    }
}
