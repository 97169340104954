header {
    background: url(./assets/background.jpg);
    background-size: cover;
    color: rgb(255, 255, 255);
    padding: 22px 0px 40px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-item {
    margin: 10px 20px;
    font-weight: 500;
}

.header-button-lang {
    color: #b7b095;
    font-size: 22px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    font-weight: 500;
}

.nav-item {
    color: #b7b095;
    font-size: 22px;
    cursor: pointer;
}
.nav-item-active {
    color: #ffffff;
    font-size: 22px;
    cursor: pointer;
    border-bottom: 1px solid white;
}

.header-logo {
    cursor: pointer;
    max-width: 105px;
    min-width: 100px;
    width: 10vw;
}

.mobile-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #282928;
    z-index: 10000;
    overflow: hidden;
    position: fixed;
    max-height: 100vh;
}
.mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.mobile-menu-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 15px;
    padding-top: 15px;
}
.mobile-menu-close-button {
    width: 35px;
    height: 35px;
    border-radius: 3px;
}
.mobile-menu-menu-button {
    width: 35px;
    background: none;
    border: none;
    outline: none;
    height: 35px;
    margin-left: 15px;
    margin-bottom: 15px;
    display: none;
}
@media all and (max-width: 800px) {
    .header-inner {
        display: none;
    }
    .mobile-menu-menu-button {
        display: block;
    }
}
