.home-welcome {
    padding: 70px 0px 100px 0px;
}

.home-welcome-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.home-welcome-title {
    color: rgb(52, 51, 50);
    font-size: 60px;
    line-height: 60px;
    max-width: 629px;
    margin-bottom: 45px;
}

.home-welcome-description {
    font-size: 20px;
    color: rgb(136, 132, 115);
    line-height: 25px;
    max-width: 629px;
    margin-bottom: 55px;
}

.home-welcome-carousel {
    max-width: 629px;
    border-radius: 30px;
}

.home-welcome-image {
    max-width: 346px;
    margin-left: 40px;
    border-radius: 10px;
}

.home-delivery {
    background-color: rgb(241, 238, 232);
    padding: 40px 0px;
}

.home-delivery-inner {
    display: flex;
    background-color: white;
}

.home-delivery-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    padding: 60px 40px;
    text-align: center;
}

.home-delivery-title {
    color: rgb(183, 176, 149);
    font-size: 36px;
    font-family: 'Cormorant Infant', sans-serif;
    margin-bottom: 10px;
    font-weight: 400;
}

.home-delivery-title-decoration-line-lg {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 89px;
    margin-bottom: 4px;
}

.home-delivery-title-decoration-line-md {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 47px;
    margin-bottom: 25px;
}

.home-delivery-description-1 {
    font-size: 50px;
    line-height: 50px;
    color: rgb(29, 29, 29);
    font-weight: 700;
    margin-bottom: 25px;
}
.home-delivery-description-2 {
    color: rgb(52, 51, 50);
    font-size: 20px;
    margin-bottom: 40px;
    max-width: 80%;
}
.home-delivery-image {
    max-width: 50%;
    max-height: 576px;
    width: 550px;
    height: 576px;
    object-fit: cover;
    border-radius: 5px;
}

.home-delivery-button {
    color: rgb(52, 51, 50);
    border-bottom: 1px solid rgb(52, 51, 50);
    padding: 9px 0px;
    font-weight: 700;
    font-size: 23px;
}

.home-carousel {
    background-image: linear-gradient(to bottom, #1a1c1b 20%, rgba(255, 255, 255, 0) 0%), url('assets/carousel-bg.jpg');
    padding-bottom: 100px;
    transition: 10s ease-in-out;
}

.home-carousel-container {
    max-width: calc(100vw - 100px);
    width: calc(100vw - 100px);
    margin: 0 auto;
}

.home-carousel-image-container {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    min-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
}
.home-carousel-container-size {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    min-height: 600px;
}
.home-carousel-image {
    width: 100%;
}

.home-carousel-content {
    font-size: 40px;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 750px;
    max-width: 75vw;
}
.home-carousel-review {
    color: white;
    padding-left: 50px;
    font-weight: 600;
    line-height: 40px;
    font-size: 40px;
    margin-bottom: 70px;
}

.home-carousel-review-author {
    color: rgb(183, 176, 149);
    font-size: 45px;
    font-weight: 400;
    font-family: 'Cormorant Infant';
    padding-left: 50px;
    margin-bottom: 90px;
}

.home-carousel-button-container {
    padding-left: 50px;
    margin-bottom: 90px;
    display: flex;
}

.home-carousel-button-active {
    border: 1px solid rgb(42, 82, 72);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.home-carousel-button {
    border: 1px solid rgba(183, 176, 149, 0);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.home-carousel-button-inner {
    width: 6px;
    height: 6px;
    background-color: rgb(183, 176, 149);
    border-radius: 50%;
}

.home-welcome-carousel-button-container {
    margin-top: -50px;
    display: flex;
    justify-content: center;
}

.home-welcome-carousel-button-active {
    border: 1px solid rgb(42, 82, 72);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.home-welcome-carousel-button {
    border: 1px solid rgba(183, 176, 149, 0);
    border-radius: 50%;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.home-welcome-carousel-button-inner {
    width: 6px;
    height: 6px;
    background-color: rgb(183, 176, 149);
    border-radius: 50%;
}

@media all and (max-width: 1100px) {
    .home-welcome-image {
        display: none;
    }
    .home-welcome-title {
        max-width: 100%;
    }
    .home-welcome-description {
        max-width: 100%;
    }
    .home-welcome {
        text-align: center;
    }
    .home-welcome-inner {
        align-items: center;
        justify-content: center;
    }
    .home-welcome-carousel {
        max-width: 100%;
    }
}
@media all and (max-width: 950px) {
    .home-delivery-title {
        font-size: 32px;
    }
    .home-welcome-title {
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .home-welcome-description {
        margin-bottom: 20px;
    }
    .home-carousel-image-container {
        min-height: 400px;
    }
    .home-delivery-description-1 {
        font-size: 27px;
        line-height: 27px;
    }
    .home-delivery-button {
        font-size: 20px;
    }
    .home-welcome {
        padding: 40px 0px 40px 0px;
    }
    .home-carousel-review {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 60px;
    }
    .home-carousel-review-author {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 70px;
    }
    .home-carousel {
        padding-bottom: 40px;
    }
    .home-carousel-button-container {
        margin-bottom: 40px;
    }
    .home-carousel-image-container {
        max-height: 450px;
        width: 95vw;
    }
    .home-carousel-container {
        max-width: 95vw;
        width: 95vw;
    }
}

@media all and (max-width: 750px) {
    .home-delivery-inner {
        flex-direction: column;
        align-items: center;
    }
    .home-carousel-image-container {
        max-height: 350px;
    }

    .home-delivery-image {
        max-width: 100%;
        margin-top: 40px;
        max-height: 500px;
    }
    .home-carousel-image-container {
        height: calc(100vh - 350px);
    }
    .home-carousel-review {
        margin-bottom: 20px;
    }
    .home-carousel-review-author {
        margin-bottom: 40px;
    }
}

@media all and (max-width: 630px) {
    .home-carousel-review-author {
        font-size: 24px;
        line-height: 24px;
    }
    .home-carousel-review {
        font-size: 24px;
        line-height: 24px;
    }
    .home-delivery-image {
        margin-top: 0px;
    }
    .home-carousel-image-container {
        min-height: 300px;
    }
}

@media all and (max-width: 550px) {
    .home-carousel-review {
        padding-left: 30px;
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 22px;
    }
    .home-carousel-review-author {
        padding-left: 30px;
        margin-bottom: 15px;
        font-size: 21px;
        line-height: 21px;
    }
    .home-carousel-button-container {
        padding-left: 30px;
    }
    .home-carousel-image-container {
        max-height: 300px;
    }

    .home-carousel-button-container {
        padding-left: 20px;
        margin-bottom: 20px;
    }
    .home-delivery-image {
        max-height: 400px;
    }
    .home-delivery-content {
        padding: 30px 20px;
    }
}

.fade-in-animation {
    animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
    from {
        opacity: 30%;
    }
    to {
        opacity: 100%;
    }
}
