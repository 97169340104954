.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0px;
}

.menu-description {
    max-width: 650px;
    color: rgb(136, 132, 115);
    margin-bottom: 30px;
}

.menu-description p {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.6px;
}
.menu-title {
    color: rgb(183, 176, 149);
    font-size: 40px;
    font-family: 'Cormorant Infant', sans-serif;
    margin-bottom: 15px;
}

.menu-title-decoration-line-lg {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 89px;
    margin-bottom: 4px;
}
.menu-title-decoration-line-md {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 47px;
    margin-bottom: 35px;
}

.menu-download {
    background-color: rgb(235, 235, 235);
    color: rgb(102, 102, 102);
    font-size: 14px;
    padding: 14px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: max-content;
}

.menu-dowload-icon {
    margin-right: 7px;
    width: 20px;
}

.menu-image {
    max-width: 346px;
    width: 50%;
    margin-right: 50px;
    border-radius: 10px;
}

@media all and (max-width: 1300px) {
    .menu {
        align-items: flex-start;
        padding: 100px 0px;
    }
}

@media all and (max-width: 1100px) {
    .menu {
        align-items: flex-start;
        padding: 50px 0px;
    }
}
@media all and (max-width: 900px) {
    .menu {
        padding: 40px 0px;
    }

    .menu-image {
        width: 40%;
    }
}

@media all and (max-width: 900px) {
    .menu-image {
        display: none;
    }
}
