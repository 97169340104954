.gallery {
    padding: 126px 0px;
}

.gallery-content {
    display: flex;
    gap: 5px;
    padding: 10px;
}

.gallery-column {
    width: calc((100% - 10px) / 3);
}

.gallery-item {
    position: relative;
    cursor: pointer;
}

.gallery-item-mask {
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: calc(100% - 5px);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-search-button {
    background-color: rgba(255, 255, 255, 0.683);
    border-radius: 50%;
    padding: 9px;
    width: 50px;
    height: 50px;
    margin: 5px;
    min-height: 0px;
    min-width: 0px;
    border: none;
    cursor: pointer;
}

.gallery-search-icon {
    width: 30px;
}

.gallery-pagination-container {
    display: flex;
    justify-content: center;
}

.gallery-pagination-page {
    background: rgb(241, 238, 232);
    color: rgb(52, 51, 50);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin: 5px;
    line-height: 36.352px;
    cursor: pointer;
    padding-bottom: 4px;
}

.gallery-pagination-page-active {
    background: rgb(120, 144, 122);
    color: rgb(255, 255, 255);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    line-height: 36.352px;
    margin: 5px;
    cursor: pointer;
    padding-bottom: 4px;
}

.gallery-fullscreen-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #3b3b3b7e;
    z-index: 10000;
    overflow: hidden;
    position: fixed;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-fullscreen-image > img {
    max-height: 90vh;
    max-width: 90vw;
    border-radius: 10px;
}

.gallery-close-button {
    width: 44px;
    height: 44px;
    border-radius: 3px;
}

.gallery-fullscreen-actions {
    position: absolute;
    right: 0;
    top: 1px;
}

.gallery-fullscreen-actions button {
    margin: 1px;
    cursor: pointer;
}

@media all and (max-width: 1300px) {
    .gallery {
        align-items: flex-start;
        padding: 100px 0px;
    }
}

@media all and (max-width: 1100px) {
    .gallery {
        align-items: flex-start;
        padding: 50px 0px;
    }
}

@media all and (max-width: 900px) {
    .gallery {
        padding: 40px 0px;
    }
}

@media all and (max-width: 650px) {
    .gallery-content {
        flex-direction: column;
    }
    .gallery-column {
        width: 100%;
    }
}
