.contacts {
    display: flex;
    justify-content: space-between;
    padding: 124px 0px;
}

.contacts-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 252px;
}

.contacts-title {
    color: rgb(52, 51, 50);
    font-size: 27px;
    margin-bottom: 15px;
    font-weight: 600;
}

.contacts-title-decoration-line-lg {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 89px;
    margin-bottom: 4px;
}
.contacts-title-decoration-line-md {
    background-color: rgb(175, 169, 143);
    height: 2px;
    width: 47px;
    margin-bottom: 15px;
}

.contacts-description {
    color: rgb(136, 132, 115);
    font-size: 20px;
}

.contacts-map {
    width: 100%;
    height: 60vh;
    min-height: 300px;
    margin-bottom: 124px;
}

@media all and (max-width: 1300px) {
    .contacts {
        align-items: flex-start;
        padding: 100px 0px;
    }
    .contacts-item {
        min-width: 200px;
    }
}
@media all and (max-width: 1100px) {
    .contacts {
        align-items: flex-start;
        padding: 50px 0px;
    }
}

@media all and (max-width: 900px) {
    .contacts {
        flex-direction: column;
        align-items: center;
        padding: 40px 0px;
    }
    .contacts-item {
        margin-bottom: 40px;
    }
    .contacts-map {
        margin-bottom: 40px;
    }
}
